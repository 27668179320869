<template>
  <div class="text-center pt-1">
    <Tick />
    <h1 class="mt-5 mb-5">Congratulations!</h1>
    <div class="msg">
      You will soon receive an
      email with details of your
      redemption.
    </div>
    <h1 class="mt-5">Enjoy!</h1>
  </div>
</template>

<script>
import Tick from "@/components/Tick.vue"

export default {
  name: 'ThankYou',
  data: function() {
    return {
    }
  },
  components: {
    Tick
  }
}
</script>

<style lang="scss" scoped>
  .msg {
    font-size: 1.2em;
    width: 90%;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
</style>