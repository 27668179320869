import jwt_decode from 'jwt-decode';
import axios from 'axios';
import cognito from './cognito.service';

export default {

  getUserInfo: () : Promise<any> => {
    const jwtToken = cognito.auth.getSignInUserSession().getAccessToken().getJwtToken();
    const USERINFO_URL = 'https://' + cognito.auth.getAppWebDomain() + '/oauth2/userInfo';
    const requestData = {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    }

    return axios.get(USERINFO_URL, requestData).then(response => {
      return response.data;
    });
  },

  getGroupId: () : Promise<any> => {
    const cognitoUser = cognito.userPool.getCurrentUser();

    if (cognitoUser != null) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err: any, session: any) => {
          if (err) {
            //console.error(err);
            reject(err);
          }
          //console.log('session validity: ' + session.isValid());
          const sessionIdInfo : any = jwt_decode(session.getIdToken().jwtToken);
          resolve(sessionIdInfo['cognito:groups']);
        });
      });
    }
    else {
      return Promise.reject(null);
    }
  }
}