<template>
  <div v-if="product">
    <GiftCard v-if="product.type === 2 || product.type === 4" :product="product" />
    <SingleProduct v-else-if="!product.isGrouped" :product="product" />
    <GroupProduct v-else :groupedProduct="product" />
  </div>
</template>

<script>
// @ is an alias to /src
import SingleProduct from '@/components/SingleProduct.vue'
import GroupProduct from '@/components/GroupProduct.vue'
import GiftCard from '@/components/GiftCard.vue'
import {mapState} from "vuex";
import _ from "lodash";

export default {
  props: ['code'],
  name: 'Product',
  components: {
    SingleProduct,
    GroupProduct,
    GiftCard
  },
  computed: {
    product: function() {
      if (this.merchandise && this.giftCards && this.eVouchers) {
        let result = _.find(this.merchandise, (m) => m.code === this.code);

        if (!result) {
          result = _.find(this.giftCards, (m) => m.code === this.code);
        }

        if (!result) {
          result = _.find(this.eVouchers, (m) => m.code === this.code);
        }

        return result;
      } else {
        return null;
      }
    },
    ...mapState(['merchandise', 'giftCards', 'eVouchers'])
  },
}
</script>
