<template>
  <div v-if="product" class="product-panel d-flex flex-row py-5">
    <div class="f-2 text-center">
      <img class="product-image" :srcset="product.imgSrcSet" :sizes="product.imgSizes" />
    </div>
    <div class="f-1 mx-5">
      <h2>{{product.name}}</h2>
      <div class="mb-2 fs-6">
        Code: {{product.identifier}}
      </div>
      <div v-if="!product.out_of_stock">
        <h5>{{product.points}} {{PointsName}}</h5>
        <div class="d-flex flex-row">
          <div class="f-even">
            Quantity: 
          </div>
          <div class="f-even">
            <select v-model="quantity">
              <option v-for="index in [1,2,3,4,5,6,7,8,9,10]" :key="index">{{index}}</option>
            </select>
          </div>
        </div>
        <div class="my-3">
          <button class="btn btn-primary btn-sm" v-on:click="addToCart()">Add to cart</button>
        </div>
      </div>
      <div v-else>
        <h3>Out of Stock</h3>
      </div>
      <div class="py-4 fs-6" v-html="product.copy"></div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import {PointsName} from '@/services/config.service'
import cartService from '@/services/cart.service'
//import _ from "lodash";

export default {
  props: ['product'],
  data: function() {
    return {
      quantity: 1,
      PointsName
    }
  },
  name: 'SingleProduct',
  components: {
  },
  methods: {
    addToCart() {
      const cartItem = {
        productIdentifier: this.product.identifier,
        type: this.product.type,
        name: this.product.name,
        description: this.product.copy,
        points: this.product.points,
        price: this.product.price,
        quantity: this.quantity,
        totalPoints: this.product.points * this.quantity,
        totalPrice: this.product.price * this.quantity
      };
      //console.log(cartItem);
      cartService.addCartItem(cartItem);
    }
  }
}
</script>

<style lang="scss" scoped>
.product-image {
  max-width: 100%;
  max-height: 60vh;
}

</style>