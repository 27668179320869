<template>
  <div id="nav" class="container-fluid px-0" :class="navClass">
    <div id="header" class="row gx-0 px-4" :class="navClass">
      <div id="logo" class="col-2">
        <router-link to="/"><img alt="Core logo" src="@/assets/logo.svg"></router-link>
      </div>
      <div id="search-panel" class="col">
        <input
          v-model="searchText"
          v-on:keyup.enter="performSearch"
          class="form-control"
          placeholder="Search" />
      </div>
      <div class="nav-btn col-1">
        <router-link to="/cart">
          Cart <span v-if="cart.length > 0">({{cart.length}})</span>
        </router-link>
      </div>
      <div class="nav-btn col-1">
        <router-link to="/contact">Contact</router-link>
      </div>
      <div class="nav-btn col-1">
        <div v-if="loggedIn">
          <router-link to="/logout">Sign&nbsp;out</router-link>
        </div>
        <div v-else>
          <router-link to="/login">Sign&nbsp;in</router-link>
        </div>
      </div>
    </div>
    <div id="category-panel" class="row gx-0" :class="navClass">
      <span v-for="menu in menus" :key="menu.menu_id">
        <router-link :to="menu.page_name.toLowerCase()">{{menu.display_name}}</router-link>
      </span>
      <span v-if="participant && participant.balance !== undefined">
        My {{PointsName}}: {{formatNumber(participant.balance)}}
      </span>
    </div>
  </div>
</template>

<script>
import numberUtils from '@/utils/number.utils'
import {PointsName} from '@/services/config.service'
import {mapState} from "vuex";

export default {
  data: function() {
    return {
      PointsName,
      searchText: '',
      resizeTimer: 0,
      navClass: ''
    };
  },
  computed: {
    ...mapState(['participant', 'loggedIn', 'sessionId', 'menus', 'cart'])
  },
  methods: {
    ...numberUtils,
    performSearch: function() {
      const search = this.searchText;

      // reset search text
      this.searchText = '';

      this.$router.push({
          name: 'search',
          params: {
              searchText: search
          }
      });
    }
  },
  mounted() {
    this.resizeTimer = setInterval(() => {
      if (scrollY > 300) {
        this.navClass = 'hide-header';
      } else {
        this.navClass = '';
      }
    }, 100);
  },
  beforeUnmount() {
    if (this.resizeTimer !== 0) {
      clearInterval(this.resizeTimer);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100vw;
  background-color: color(nav, base);
  color: color(nav, text);
  text-align: center;
  z-index: 100;
}

#nav .row:nth-child(1) {
  height: 100%;
}

#nav a {
  font-weight: bold;
  text-decoration: none;
  color: color(navAnchor, base);
}
#nav a:hover {
  font-weight: bold;
  color: color(navAnchor, hover);
}

#nav a.router-link-exact-active {
  color: color(navAnchor, active);
}

#nav .nav-btn {
  border-left: solid 1px color(nav, divider);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

#nav.hide-header {
  height: 40px;
}

#header.hide-header {
  display: none;
}

#category-panel.hide-header {
  border-top: solid 1px color(navCategories, base);
}

#logo {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

#logo img {
  height: 44px;
  width: auto;
}

#category-panel {
  background-color: color(navCategories, base);
  color: color(navCategories, text);
  border-top: solid 1px color(navCategories, divider);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#category-panel span {
  flex: 0;
  white-space: nowrap;
  padding: 0 0 0 40px;
}

#search-panel {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
}

#search-panel .form-control {
  width: 60%;
}

</style>
