<template>
  <div class="heading text-center">
    eVouchers
  </div>
  <div v-if="eVouchers.length === 0" class="product-panel">
    <ProductLoadingPanel
      v-for="n in 20"
      v-bind:key="n"></ProductLoadingPanel>
  </div>
  <div v-else-if="groupedProducts.length === 0">
    No eVouchers found
  </div>
  <div v-else class="product-panel">
    <ProductPanel
      v-for="product in groupedProducts"
      v-bind:key="product.product_id"
      :product="product"></ProductPanel>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductPanel from '@/components/ProductPanel.vue'
import ProductLoadingPanel from '@/components/ProductLoadingPanel.vue'
import productService from '@/services/product.service'
import {mapState} from "vuex";

export default {
  data: function() {
    return {
    }
  },
  name: 'eVouchers',
  components: {
    ProductPanel,
    ProductLoadingPanel
  },
  computed: {
    groupedProducts: function() {
      const combinedProducts = productService.combineGroupedProducts(this.eVouchers);
      return combinedProducts;
    },
    ...mapState(['eVouchers'])
  }
}
</script>

<style lang="scss" scoped>
.product-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

</style>