import axios from 'axios';
import {Menu} from "@/models/menu.model";
import headerUtils from "@/utils/header.utils";
import { CartItem } from '@/models/cartitem.model';
import { State, store } from '@/store';
import { uuid } from 'vue-uuid'; 
import _ from 'lodash';

export default {

  addCartItem: (cartItem: CartItem) : CartItem[] => {
    const myStore: State = store.state;

    if (!cartItem.cartId || cartItem.cartId.length === 0) {
      cartItem.cartId = uuid.v4();
    }

    const cart = myStore.cart;
    cart.push(cartItem);

    store.commit("setCart", cart);
    
    return cart;
  },

  removeCartItem: (cartId: string) : CartItem[] => {
    const myStore: State = store.state;

    const cart: CartItem[] = JSON.parse(JSON.stringify(myStore.cart));
    
    _.remove(cart, (cartItem) => cartItem.cartId === cartId);

    store.commit("setCart", cart);
    
    return cart;
  },

  clearCart: () : void => {
    const myStore: State = store.state;
    store.commit("setCart", []);
  },

}