<template>
  <div id="footer" class="container-fluid px-0 mt-5 pb-3">
    <!--
    <div class="d-flex flex-row mt-4">
      <div class="f-even">
        TODO
      </div>
      <div class="f-even">
        TODO
      </div>
    </div>
    -->
    <div class="text-center pt-4">
      <div>© {{(new Date()).getFullYear()}} Aciem, all rights reserved</div>
      <div>powered by Prometheus</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data: function() {
    return {
    };
  },
  computed: {
    ...mapState(['loggedIn', 'sessionId', 'menus', 'cart'])
  },
  methods: {
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#footer {
  min-height: 100px;
  background-color: color(nav, base);
  color: color(nav, text);
  text-align: center;
  z-index: 0;
}
</style>
