export const JobNo: string = (window as any)._config.VUE_APP_JOB_NO;
export const PointsName: string = (window as any)._config.VUE_APP_POINTS_NAME;
export const AppUrl: string = (window as any)._config.VUE_APP_APP_URL;
export const MinVoucherRedemption: number = Number((window as any)._config.VUE_APP_MIN_VOUCHER_REDEMPTION);

export const CognitoRedirectURI: string = (window as any)._config.VUE_APP_COGNITO_REDIRECT_URI;
export const CognitoRedirectSignout: string = (window as any)._config.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
export const CognitoUserPoolID: string = (window as any)._config.VUE_APP_COGNITO_USERPOOL_ID;
export const CognitoClientID: string = (window as any)._config.VUE_APP_COGNITO_CLIENT_ID;
export const CognitoAppDomain: string = (window as any)._config.VUE_APP_COGNITO_APP_DOMAIN;
export const AWSUrl: string = (window as any)._config.VUE_APP_AWS_URL;
export const AWSProgrammeID: string = (window as any)._config.VUE_APP_AWS_PROGRAMME_ID;
export const AWSApiKey: string = (window as any)._config.VUE_APP_AWS_API_KEY;
