import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-408ceff1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["srcset", "sizes"]
const _hoisted_2 = { class: "product-price" }
const _hoisted_3 = {
  key: 0,
  class: "fs-6 fw-bold"
}
const _hoisted_4 = { class: "product-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "product-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openProductDetail()))
  }, [
    _createElementVNode("img", {
      class: "product-image",
      srcset: $props.product.thumbSrcSet,
      sizes: $props.product.thumbSizes
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      ($props.product.isGrouped || $props.product.type == 2 || $props.product.type == 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "FROM"))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString($props.product.price) + " " + _toDisplayString(_ctx.pointsName), 1)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString($props.product.name), 1)
  ]))
}