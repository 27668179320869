import axios from 'axios';
import {AWSProduct, Product} from "@/models/product.model";
import {AWSUrl, AWSProgrammeID} from '@/services/config.service';
import headerUtils from "@/utils/header.utils";
import _ from "lodash";

export default {
  getProductByCode: (code: string) : Promise<Product | null> => {
    const GET_PRODUCT_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/product/getbycode/' + code;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<Product | null>(GET_PRODUCT_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        const awsProducts = response.data.products as AWSProduct[];

        if (awsProducts.length > 0) {
          return new Product(awsProducts[0]);
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  },
  
  getProductsByType: (type: number) : Promise<Product[]> => {
    const GET_PRODUCTS_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/product/getbytype/' + type;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<Product[]>(GET_PRODUCTS_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        const awsProducts = response.data.products as AWSProduct[];
        
        return awsProducts.map((awsProduct) => {
          return new Product(awsProduct);
        });
      }
      else {
        return [] as Product[];
      }
    });
  },

  getProductsByIdentifier: (identifier: string) : Promise<Product | null> => {
    const GET_PRODUCTS_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/product/getbyidentifier/' + identifier;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<Product | null>(GET_PRODUCTS_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        const awsProducts = response.data.products as AWSProduct[];
        if (awsProducts && awsProducts.length > 0) {
          return new Product(awsProducts[0]);
        } else {
          return null
        }
      }
      else {
        return null;
      }
    });
  },

  getProductsByGroup: (groupId: string) : Promise<Product[]> => {
    const GET_PRODUCTS_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/product/getbygroup/' + groupId;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<Product[]>(GET_PRODUCTS_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        const awsProducts = response.data.products as AWSProduct[];

        return awsProducts.map((awsProduct) => {
          return new Product(awsProduct);
        });
      }
      else {
        return [] as Product[];
      }
    });
  },

  getProductsByMenu: (menuId: string) : Promise<Product[]> => {
    const GET_PRODUCTS_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/product/getbymenu/' + menuId;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<Product[]>(GET_PRODUCTS_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        const awsProducts = response.data.products as AWSProduct[];

        return awsProducts.map((awsProduct) => {
          return new Product(awsProduct);
        });
      }
      else {
        return [] as Product[];
      }
    });
  },

  getProductsByPage: (pageName: string) : Promise<Product[]> => {
    const GET_PRODUCTS_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/product/getbypage';

    const headers = {
      headers: headerUtils.getBaseHeaders()
    };

    const postData = JSON.stringify({
        pageName
      });

    return axios.post(GET_PRODUCTS_URL, postData, headers).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        const awsProducts = response.data.products as AWSProduct[];

        return awsProducts.map((awsProduct) => {
          return new Product(awsProduct);
        });
      }
      else {
        return [] as Product[];
      }
    });
  },

  getProductsBySearch: (searchText: string, excludedSuppliers: string[]) : Promise<Product[]> => {
    const GET_PRODUCTS_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/product/getbysearch/';

    const headers = {
      headers: headerUtils.getBaseHeaders()
    };

    const postData = JSON.stringify({
      searchText
    });

    return axios.post(GET_PRODUCTS_URL, postData, headers).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        const awsProducts = response.data.products as AWSProduct[];

        const allProducts = awsProducts.map((awsProduct) => {
          return new Product(awsProduct);
        });

        // remove excluded suppliers 
        allProducts.filter((product) => {
          return !excludedSuppliers.includes(product.supplier) &&
                 !excludedSuppliers.includes(product.group_name); //group_name is a hack due to ePay being a supplier who may also supply voucher
        });
  
        const products: Product[] = [];
        
        //Exclude grouped items
        const foundGroups: string[] = [];
        allProducts.forEach((product) => {
          let addProduct = true;
          if (product.group_name && product.group_name.length > 0) {
            if (!foundGroups.includes(product.group_name)) {
              foundGroups.push(product.group_name);
            } else {
              addProduct = false;
            }
          }

          if (addProduct) {
            products.push(product);
          }
        });

        return products;
      }
      else {
        return [] as Product[];
      }
    });
  },

  /**
   * Combines grouped products, so that only a single product from that group is returned
   * @param products products to filter grouped products from
   * @returns 
   */
  combineGroupedProducts: (products: Product[]) : Product[] => {
    const output: Product[] = [];

    products.forEach(product => {
      if (product.group_name && product.group_name.length > 0) {
        // ensure the group has not already been added
        const found = _.countBy(output, p => p.group_name === product.group_name).true > 0;
        if (!found) {
          // find lowest price within group
          const groupProducts = _.filter(products, p => p.group_name === product.group_name);
          const lowestPrice = _.sortBy(groupProducts, 'price').reverse()[0].price;
          const lowestPoints = _.sortBy(groupProducts, 'points').reverse()[0].points;
          
          // clone product
          const productCopy: Product = Object.create(
            Object.getPrototypeOf(product), 
            Object.getOwnPropertyDescriptors(product) 
          ) as Product;

          productCopy.price = lowestPrice;
          productCopy.points = lowestPoints;
          productCopy.name = productCopy.group_name;
          output.push(productCopy);
        }
      } else {
        output.push(product);
      }
    });

    return output;
  }
}