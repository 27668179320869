<template>
  <div class="container-fluid px-0">
    <div class="d-flex flex-row">
      <div class="f-even pt-5">
        <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Parnell%20Road,%20Parnell,%20Auckland,%20New%20Zealand+(Aciem)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
      </div>
      <div class="f-even">
        <div class="heading text-center">
          Contact
        </div>
        <div class="text-center">
          TODO
        </div>
      </div>  
    </div>
  </div>

  
</template>

<script>
export default {
  data: function(){
    return {
    }
  }
}
</script>