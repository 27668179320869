// store.ts
import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import cognitoService from '@/services/cognito.service'
import { Participant } from './models/participant.model'
import { Product } from './models/product.model'
import { Menu } from './models/menu.model'
import { CartItem } from './models/cartitem.model'
import { CheckoutInfo } from './models/checkoutinfo.model'

// define your typings for the store state
export interface State {
  group: string,
  cognitoInfo: any,
  participant: Participant,
  loggedIn: boolean,
  sessionId: string,
  cart: CartItem[],
  menus: Menu[],
  merchandise: Product[],
  giftCards: Product[],
  eVouchers: Product[],
  checkoutInfo: CheckoutInfo
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    group: '',
    cognitoInfo: {},
    participant: ({} as any),
    loggedIn: cognitoService.isSignedIn(),
    sessionId: '',
    cart: (localStorage.cart) ? JSON.parse(localStorage.cart) : [],
    menus: [],
    merchandise: [],
    giftCards: [],
    eVouchers: [],
    checkoutInfo: new CheckoutInfo()
  },
  mutations: {
    setGroup (state: State, newValue: string) {
      state.group = newValue;
    },
    setParticipant (state: State, newValue: Participant) {
      state.participant = newValue;
    },
    setLoggedIn (state: State, newValue: boolean) {
      state.loggedIn = newValue;
    },
    setLoggedOut (state: State) {
      state.loggedIn = false;
      state.cognitoInfo = {};
    },
    setCognitoInfo (state: State, newValue: any) {
      state.cognitoInfo = newValue;
    },
    setSessionId (state: State,  newValue: string) {
      state.sessionId = newValue;
    },
    setMenus (state: State, newValue: Menu[]) {
      state.menus = newValue;
    },
    setMerchandise (state: State, newValue: Product[]) {
      state.merchandise = newValue;
    },
    setGiftCards (state: State, newValue: Product[]) {
      state.giftCards = newValue;
    },
    setEVouchers (state: State, newValue: Product[]) {
      state.eVouchers = newValue;
    },
    setCart (state: State, newValue: CartItem[]) {
      state.cart = newValue;
      localStorage.cart = JSON.stringify(newValue);
    },
    setCheckoutInfo (state: State, newValue: CheckoutInfo) {
      state.checkoutInfo = newValue;
    }
  },
  /* Getters is like having a computed variable (but bound to state!)  Access them through store.getters.dummyGetter
  getters: {
    dummyGetter: state => {
      return state.sessionId;
    }
  }
  */
})