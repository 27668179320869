import cognito from "@/services/cognito.service";

export default {
    getBaseHeaders() {
        const jwtToken = cognito.auth.getSignInUserSession().getAccessToken().getJwtToken();
        
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-api-key': (window as any)._config.VUE_APP_AWS_API_KEY,
            'Authorization': 'Bearer ' + jwtToken
        };
    }
}
