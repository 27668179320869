<template>
<div class="row pt-4">
  <div class="col text-center">
    <h2>Logged Out successfully</h2>
    <router-link to="/login">Login</router-link>
  </div>
</div>
</template>

<script>
export default {
  mounted: function() {
  }
}
</script>