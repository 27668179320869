<template>
  <div class="product-panel" v-if="merchandise.length > 0">
    <ProductPanel
      v-for="product in groupedMerchandise"
      v-bind:key="product.product_id"
      :product="product"></ProductPanel>
  </div>
  <div class="product-panel" v-if="merchandise.length === 0">
    <ProductLoadingPanel
      v-for="n in 20"
      v-bind:key="n"></ProductLoadingPanel>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductPanel from '@/components/ProductPanel.vue'
import ProductLoadingPanel from '@/components/ProductLoadingPanel.vue'
import productService from '@/services/product.service'
import {mapState} from "vuex";

export default {
  data: function() {
    return {
    }
  },
  name: 'Home',
  components: {
    ProductPanel,
    ProductLoadingPanel
  },
  computed: {
    groupedMerchandise: function() {
      const combinedProducts = productService.combineGroupedProducts(this.merchandise);
      //console.log(combinedProducts);
      return combinedProducts;
    },
    ...mapState(['merchandise'])
  }
}
</script>

<style lang="scss" scoped>
.product-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

</style>