import axios from 'axios';
import headerUtils from "@/utils/header.utils";
import {Redemption} from "@/models/redemption.model";
import {AWSUrl, AWSProgrammeID} from '@/services/config.service';
import cartService from './cart.service';
import { State, store } from '@/store';

export default {

  createRedemption: (data: Redemption) : Promise<boolean> => {
    const CREATE_REDEMPTION_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/redemption';

    const headers = {
      headers: headerUtils.getBaseHeaders()
    };

    const postData = JSON.stringify(data);

    return axios.post(CREATE_REDEMPTION_URL, postData, headers).then((response: any) => {
      const success = (response.data && response.data.status === 'OK');

      if (success) {
        const myStore: State = store.state;

        // update participant points balance
        const part = myStore.participant;
        part.balance -= data.pointsSpent;
        store.commit('setParticipant', part);

        // reset cart
        cartService.clearCart();
      }

      return success;
    });
  },
}
