export class CheckoutInfo {
  firstName: string = '';
  lastName: string = '';
  phonePrefix: string = '';
  phoneNumber: string = '';
  email: string = '';
  confirmEmail: string = '';
  instorePickup: boolean = false;
  company: string = '';
  numberAndStreet: string = '';
  suburb: string = '';
  city: string = '';
  state: string = '';
  postCode: string = '';
  country: string = '';
  //cardName: string = '';
  //cardNumber: string = '';
  //cardExpiryMM: string = '';
  //cardExpiryYY: string = '';
  //cardCVV: string = '';

  constructor() {

  }
}