import { createApp } from 'vue'
import App from './App.vue'
import {store, key, State} from './store'
import router from './router'
import sessionService from '@/services/session.service'
import cognitoService from '@/services/cognito.service'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/scss/global.scss"
import productService from './services/product.service'
import participantService from './services/participant.service'
import menuService from './services/menu.service'
import _ from 'lodash'

createApp(App)
  .use(router)
  .use(store, key)
  .mount('#app');

// load menus
menuService.getAllMenus().then(menuItems => {
  menuItems = _.sortBy(menuItems, 'sort_order');
  store.commit("setMenus", menuItems);
});

// load merchandise
productService.getProductsByType(1).then(products => {
  store.commit("setMerchandise", products);
});

// load gift cards
productService.getProductsByType(2).then(products => {
  store.commit("setGiftCards", products);
});

// load eVouchers
productService.getProductsByType(4).then(products => {
  store.commit("setEVouchers", products);
});

// retrieve a new Aciem session token if required
setInterval(() => {
    if (myStore.loggedIn && myStore.sessionId === '') {
        const memberId = cognitoService.getUserName();
        if (memberId) {
            sessionService.getSessionToken(memberId).then((sessionToken) => {
                store.commit("setSessionId", sessionToken);
                console.log('session id: ', sessionToken);

                // retrieve participant
                participantService.getParticipantDetail().then((part) => {
                  store.commit("setParticipant", part);
                  //console.log(part);
                });
            });
        }
    }
}, 1000);


// keep Aciem session token alive
const myStore: State = store.state;
setInterval(() => {
    if (myStore.loggedIn && myStore.sessionId && myStore.sessionId.length > 0) {
        console.log('refreshing session');
        sessionService.keepAlive(myStore.sessionId);
    }    
}, 1000 * 60 * 5);

// keep AWS Access Token alive
setInterval(() => {
    if (cognitoService.auth.isUserSignedIn()) {
        console.log('refreshing access token');
        const refreshToken = cognitoService.auth.getSignInUserSession().getRefreshToken().getToken();
        cognitoService.auth.refreshSession(refreshToken);
    }
}, 1000 * 60 * 30);
