<template>
  <h1 class="text-center p-3">Cart</h1>
  <div v-if="cart.length > 0" class="cart-panel">
    <div class="d-flex flex-row mb-4">
      <div class="f-1 px-2"></div>
      <div class="f-3 px-2"></div>
      <div class="f-1 px-2 fw-bold fs-5 text-end">
        Qty
      </div>
      <div class="f-1 px-2 fw-bold fs-5 text-end">
        Unit
      </div>
      <div class="f-1 px-2 fw-bold fs-5 text-end">
        {{pointsName}}
      </div>
    </div>
    <div v-for="cartItem in cart" :key="cartItem.cartId" class="cartitem-panel py-1">
      <div class="f-1 px-2 cartitem-image text-center">
        <img :srcset="product(cartItem.productIdentifier).thumbSrcSet" 
            :sizes="product(cartItem.productIdentifier).thumbSizes"
            v-on:click="gotoProduct(cartItem.productIdentifier)" />
        <div class="text-center py-3">
          <a href="javascript:void(0);" class="fs-5" v-on:click="removeFromCart(cartItem.cartId)">Remove</a>
        </div>
      </div>
      <div class="f-3 px-2">
        <p class="fs-4 fw-bold c-pointer" v-on:click="gotoProduct(cartItem.productIdentifier)">{{cartItem.name}}</p>
        <p class="fs-5" v-html="cartItem.description"></p>
      </div>
      <div class="f-1 text-end px-2">
        {{cartItem.quantity}}
      </div>
      <div class="f-1 text-end px-2">
        {{formatNumber(cartItem.points)}}
      </div>
      <div class="f-1 px-2 text-end">
        {{formatNumber(cartItem.totalPoints)}}
      </div>
    </div>
    <div class="d-flex flex-row mb-4">
      <div class="f-1 px-2"></div>
      <div class="f-3 px-2"></div>
      <div class="f-1 px-2"></div>
      <div class="f-1 fw-bold fs-5 text-end px-2">
        Total {{pointsName}}
      </div>
      <div class="f-1 px-2 fw-bold fs-4 text-end">
        {{formatNumber(total)}}
      </div>
    </div>
    <div class="d-flex flex-row mb-4">
      <div class="f-1 px-2"></div>
      <div class="f-3 px-2"></div>
      <div class="f-1 px-2"></div>
      <div class="f-1 fw-bold fs-5 text-end px-2">
        Available {{pointsName}}
      </div>
      <div class="f-1 px-2 fw-bold fs-4 text-end">
        {{formatNumber(participant.balance)}}
      </div>
    </div>
    <div class="text-end">
      <button v-on:click="checkout()" class="btn btn-primary" :class="(!sufficientPoints) ? 'disabled':''">Checkout</button>
    </div>
  </div>
  <div v-if="cart.length === 0">
    <p>Your cart is empty</p>
  </div>
</template>

<script>
// @ is an alias to /src
import numberUtils from '@/utils/number.utils'
import cartService from '@/services/cart.service'
import {PointsName} from '@/services/config.service'
import {mapState} from "vuex"
import _ from 'lodash'

export default {
  data: function() {
    return {
      pointsName: PointsName
    }
  },
  name: 'Cart',
  components: {
  },
  computed: {
    total() {
      return _.sumBy(this.cart, ci => ci.totalPoints);
    },
    sufficientPoints() {
      return this.participant.balance >= this.total;
    },
    ...mapState(['participant','cart','merchandise','giftCards','eVouchers'])
  },
  mounted() {
    // remove any items from cart that no longer exist
    // also check unit price & points are the same
    // TODO
  },
  methods: {
    ...numberUtils,
    product(productId) {
      if (this.merchandise && this.giftCards && this.eVouchers) {
        let result = _.find(this.merchandise, (m) => m.identifier === productId);

        if (!result) {
          result = _.find(this.giftCards, (m) => m.identifier === productId);
        }

        if (!result) {
          result = _.find(this.eVouchers, (m) => m.identifier === productId);
        }

        return result;
      }
      else {
        return {};
      }
    },
    removeFromCart(cartId) {
      cartService.removeCartItem(cartId);
    },
    clearCart() {
      cartService.clearCart();
    },
    gotoProduct(productId) {
      const url = this.product(productId).productUrl;
      this.$router.push(url);
    },
    checkout() {
      if (this.sufficientPoints) {
        this.$router.push({
            name: 'checkout',
            params: {}
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-panel {
  padding-top: 10px;
}

.cartitem-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  div {
  }
  .cartitem-name {
  }

  .product-name {
    cursor: pointer;
  }
}

.cartitem-image img {
  width: 90%;
  height: auto;
  max-height: 180px;
}

</style>