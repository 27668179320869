<template>
  <h1 class="text-center p-3">Checkout</h1>

  <div class="d-flex flex-row">
    <div class="flex-grow-1 details-panel">
      <div class="d-flex flex-row">
        <div class="flex-even" :class="(errors.firstName) ? 'error' : ''">
          <label for="firstName">First name</label>
          <input
            id="firstName"
            name="firstName"
            ref="firstName"
            v-model="checkoutInfo.firstName"
            class="form-control"
            type="text"
          >
          <span v-if="errors.firstName">{{errors.firstName}}</span>
        </div>
        <div class="flex-even" :class="(errors.lastName) ? 'error' : ''">
          <label for="firstName">Last name</label>
          <input
            id="lastName"
            name="lastName"
            ref="lastName"
            v-model="checkoutInfo.lastName"
            class="form-control"
            type="text"
          >
          <span v-if="errors.lastName">{{errors.lastName}}</span>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="flex-even" :class="(errors.phoneNumber) ? 'error' : ''">
          <label for="phonePrefix">Phone number</label>
          <div class="d-flex flex-row">
            <div class="pe-3">
              <input
                id="phonePrefix"
                name="phonePrefix"
                ref="phonePrefix"
                v-model="checkoutInfo.phonePrefix"
                class="form-control"
                type="text"
              >
            </div>
            <div class="flex-grow-1">
              <input
                id="phoneNumber"
                name="phoneNumber"
                ref="phoneNumber"
                v-model="checkoutInfo.phoneNumber"
                class="form-control"
                type="text"
              >
            </div>
          </div>
          <span v-if="errors.phoneNumber">{{errors.phoneNumber}}</span>
        </div>
        <div class="flex-even">
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="flex-even" :class="(errors.email) ? 'error' : ''">
          <label for="email">Email address</label>
          <input
            id="email"
            name="email"
            ref="email"
            v-model="checkoutInfo.email"
            class="form-control"
            type="email"
          >
          <span v-if="errors.email">{{errors.email}}</span>
        </div>
        <div class="flex-even" :class="(errors.confirmEmail) ? 'error' : ''">
          <label for="confirmEmail">Confirm email</label>
          <input
            id="confirmEmail"
            name="confirmEmail"
            ref="confirmEmail"
            v-model="checkoutInfo.confirmEmail"
            class="form-control"
            type="email"
          >
          <span v-if="errors.confirmEmail">{{errors.confirmEmail}}</span>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="flex-even form-check form-switch">
          <input
            id="instorePickup"
            name="instorePickup"
            ref="instorePickup"
            v-model="checkoutInfo.instorePickup"
            type="checkbox"
            class="form-check-input"
          >
          <label for="instorePickup" class="ps-2 form-check-label">Pick me up in-store?</label>
        </div>
      </div>

      <div class="delivery-details" v-if="!checkoutInfo.instorePickup">
        <div class="d-flex flex-row">
          <div class="flex-even" :class="(errors.company) ? 'error' : ''">
            <label for="company">Company (optional)</label>
            <input
              id="company"
              name="company"
              ref="company"
              v-model="checkoutInfo.company"
              class="form-control"
              type="text"
            >
            <span v-if="errors.company">{{errors.company}}</span>
          </div>
          <div class="flex-even">
            
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="flex-even" :class="(errors.numberAndStreet) ? 'error' : ''">
            <label for="numberAndStreet">Number and Street</label>
            <input
              id="numberAndStreet"
              name="numberAndStreet"
              ref="numberAndStreet"
              v-model="checkoutInfo.numberAndStreet"
              class="form-control"
              type="text"
            >
            <span v-if="errors.numberAndStreet">{{errors.numberAndStreet}}</span>
          </div>
          <div class="flex-even" :class="(errors.suburb) ? 'error' : ''">
            <label for="suburb">Suburb</label>
            <input
              id="suburb"
              name="suburb"
              ref="suburb"
              v-model="checkoutInfo.suburb"
              class="form-control"
              type="text"
            >
            <span v-if="errors.suburb">{{errors.suburb}}</span>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="flex-even" :class="(errors.city) ? 'error' : ''">
            <label for="city">Town/City</label>
            <input
              id="city"
              name="city"
              ref="city"
              v-model="checkoutInfo.city"
              class="form-control"
              type="text"
            >
            <span v-if="errors.city">{{errors.city}}</span>
          </div>
          <div class="flex-even" :class="(errors.state) ? 'error' : ''">
            <label for="state">State</label>
            <input
              id="state"
              name="state"
              ref="state"
              v-model="checkoutInfo.state"
              class="form-control"
              type="text"
            >
            <span v-if="errors.state">{{errors.state}}</span>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="flex-even" :class="(errors.country) ? 'error' : ''">
            <label for="country">Country</label>
            <input
              id="country"
              name="country"
              ref="country"
              v-model="checkoutInfo.country"
              class="form-control"
              type="text"
            >
            <span v-if="errors.country">{{errors.country}}</span>
          </div>
          <div class="flex-even" :class="(errors.postCode) ? 'error' : ''">
            <label for="postCode">Post code</label>
            <input
              id="postCode"
              name="postCode"
              ref="postCode"
              v-model="checkoutInfo.postCode"
              class="form-control"
              type="text"
            >
            <span v-if="errors.postCode">{{errors.postCode}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cartPanel">
      <cart-panel></cart-panel>
    </div>
  </div>

  <button class="btn btn-primary" v-on:click="placeOrder()">Place order</button>
</template>

<script>
// @ is an alias to /src
import redemptionService from '@/services/redemption.service'
import CartPanel from '@/components/CartPanel.vue'
import {mapState} from "vuex";
import _ from 'lodash';

export default {
  data: function() {
    return {
      processing: false,
      errors: {}
    }
  },
  name: 'Checkout',
  components: {
    CartPanel
  },
  computed: {
    total() {
      return _.sumBy(this.cart, ci => ci.totalPoints);
    },
    sufficientPoints() {
      return this.participant.balance >= this.total;
    },
    ...mapState(['sessionId', 'participant', 'cart', 'merchandise', 'giftCards', 'eVouchers', 'checkoutInfo'])
  },
  methods: {
    validateModel() {
      this.errors = {};
      if (this.checkoutInfo.firstName.length === 0) {
        this.errors.firstName = 'Please provide your first name.';
      }
      if (this.checkoutInfo.lastName.length === 0) {
        this.errors.lastName = 'Please provide your last name.';
      }
      if (this.checkoutInfo.phonePrefix.length === 0 ||
          this.checkoutInfo.phoneNumber.length === 0) {
        this.errors.phoneNumber = 'Please provide your phone number.';
      }
      if (!this.validEmail(this.checkoutInfo.email)) {
        this.errors.email = 'Please provide a valid email address.';
      } else if (this.checkoutInfo.email !== this.checkoutInfo.confirmEmail) {
        this.errors.confirmEmail = 'Email address does not match';
      }

      if (!this.checkoutInfo.instorePickup) {
        if (this.checkoutInfo.numberAndStreet.length === 0) {
          this.errors.numberAndStreet = 'Please provide your street address.';
        }
        if (this.checkoutInfo.suburb.length === 0) {
          this.errors.suburb = 'Please provide your suburb.';
        }
        if (this.checkoutInfo.city.length === 0) {
          this.errors.city = 'Please provide your city.';
        }
        if (this.checkoutInfo.state.length === 0) {
          this.errors.state = 'Please provide your state.';
        }
        if (this.checkoutInfo.country.length === 0) {
          this.errors.country = 'Please provide your country.';
        }
        if (this.checkoutInfo.postCode.length === 0) {
          this.errors.postCode = 'Please provide your post code.';
        }
      }
      
      return (Object.keys(this.errors).length === 0);
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    placeOrder() {
      if (this.sufficientPoints && !this.processing && this.validateModel()) {
        
        const deliveryDetails = this.checkoutInfo.numberAndStreet + ', ' +
                                this.checkoutInfo.suburb + ', ' +
                                this.checkoutInfo.city + ', ' +
                                ((this.checkoutInfo.state.length > 0) ? this.checkoutInfo.state + ', ' : '') +
                                this.checkoutInfo.country + ' ' +
                                this.checkoutInfo.postCode;

        const pointsSpent = _.sumBy(this.cart, (item) => item.totalPoints);
        const payPortion = 0;

        const redemption = {
          token: this.sessionId,
          deliveryDetails,
          pointsSpent,
          payPortion,
          date: new Date(),
          cartItems: this.cart,
          promoCode: ''
        };

        this.processing = true;
        redemptionService.createRedemption(redemption).then((success) => {
          this.processing = false;

          if (success) {
            this.$router.push({name: 'thankyou'});
          }
        });
      }
    }
  },
  mounted() {
    this.$refs.firstName.focus();
  }
}
</script>

<style lang="scss" scoped>
  .error input {
    border: solid 1px red;
  }
  .error span {
    color: red;
  }
  .details-panel>.flex-row>div,
  .details-panel>.delivery-details>.flex-row>div {
    margin: 0 20px 20px 0;
  }
  #phonePrefix {
    max-width: 50px;
  }
  .cartPanel {
    width: 35vw;
  }
</style>