<template>
  <div class="heading text-center">
    Search results
  </div>
  
  <div v-if="!searching && products.length === 0">
    No products found
  </div>
  <div v-else-if="!searching" class="product-panel">
    <ProductPanel
      v-for="product in products"
      v-bind:key="product.product_id"
      :product="product"></ProductPanel>
  </div>
  <div v-else class="product-panel">
    <ProductLoadingPanel
      v-for="n in 20"
      v-bind:key="n"></ProductLoadingPanel>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductPanel from '@/components/ProductPanel.vue'
import ProductLoadingPanel from '@/components/ProductLoadingPanel.vue'
import productService from '@/services/product.service'

export default {
  data: function() {
    return {
      products: [],
      searching: false
    }
  },
  props: ['searchText'],
  name: 'Home',
  components: {
    ProductPanel,
    ProductLoadingPanel
  },
  watch: {
    searchText: function(val) {
      // perform search when search text prop changes
      this.performSearch(val);
    }
  },
  created() {
    // perform initial search (computed doesn't trigger on creation)
    this.performSearch(this.searchText);
  },
  methods: {
    performSearch(val) {
      this.searching = true;
      productService.getProductsBySearch(val,[]).then(results => {
        this.searching = false;
        const combinedProducts = productService.combineGroupedProducts(results);
        this.products = combinedProducts;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>