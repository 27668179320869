import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import Contact from '@/views/Contact.vue'
import Search from '@/views/Search.vue'
import GiftCards from '@/views/GiftCards.vue'
import EVouchers from '@/views/eVouchers.vue'
import Product from '@/views/Product.vue'
import Merchandise from '@/views/Merchandise.vue'
import CartComponent from '@/views/Cart.vue'
import CheckoutComponent from '@/views/Checkout.vue'
import ThankYouComponent from '@/views/ThankYou.vue'
import ErrorComponent from '@/views/Error.vue'
import LogoutSuccess from '@/views/LogoutSuccess.vue'
import cognitoService from '@/services/cognito.service'
//import { key, State } from '@/store'
import { store } from '../store'
//import { Store, useStore } from 'vuex'
import userinfoService from '@/services/userinfo.service'

function requireAuth(to: any, from: any, next: any) {
  
  if (!cognitoService.auth.isUserSignedIn()) {
    store.commit('setLoggedIn', false);
    store.commit("setSessionId", '');
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else {
    userinfoService.getGroupId().then(result => {
      userinfoService.getUserInfo().then(response => {
        //console.log(response);
        store.commit('setLoggedIn', true);
        store.commit('setCognitoInfo', response);
        next();
      });
    });
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'login',
    component: {},
    beforeEnter: (to, from, next) => {
      cognitoService.auth.getSession();
    }
  },
  {
    path: '/login/oauth2/code/cognito', 
    component: {},
    beforeEnter: (to, from, next) => {
      const currUrl = window.location.href;
      //console.log(currUrl);
      cognitoService.auth.parseCognitoWebResponse(currUrl);
      //next();
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutSuccess,
    beforeEnter: (to, from, next) => {
      cognitoService.logout();
      store.commit("setLoggedOut");
      store.commit("setSessionId", '');
      store.commit('setCognitoInfo', {});
      store.commit('setParticipant', {});
      next();
    }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    props: true
  },
  {
    path: '/gift_cards',
    name: 'giftcards',
    component: GiftCards
  },
  {
    path: '/instant_evouchers',
    name: 'eVouchers',
    component: EVouchers
  },
  {
    path: '/merchandise',
    name: 'merchandise',
    component: Merchandise
  },
  {
    path: '/product/:code/:productName',
    name: 'product',
    component: Product,
    props: true
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartComponent,
    beforeEnter: requireAuth
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutComponent,
    beforeEnter: requireAuth
  },
  {
    path: '/thank-you',
    name: 'thankyou',
    component: ThankYouComponent,
    beforeEnter: requireAuth
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/error',
    component: ErrorComponent
  }
]

const router = createRouter({
  history: createWebHistory((window as any)._config.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
})

export default router
