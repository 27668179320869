<template>
  <Header/>
  <div id="content" class="container">
    <router-view/>
  </div>
  <Footer/>
</template>

<script>
// IMPORTANT: Don't try to import anything that has cognito as a dependency here
// as I think it is not yet initialized (performed in main.ts)
//import { mapState } from 'vuex';
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  data: function() {
    return {
    }
  },
  components: {
    Header,
    Footer
  }
  /*
  setup() {
    //let unsubscribe = ref("unsubscribe");

    //return {
    //  unsubscribe
    //}
  },
  computed: mapState(['loggedIn', 'sessionId']),
  
  created: () => {
    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'loggedIn') {
        console.log(`Updating to ${state.loggedIn}`);

        // Do whatever makes sense now
        if (state.loggedIn) {
          this.signedIn = state.loggedIn;
        }
      }
    });
  },
  beforeUnmount: () => {
    this.unsubscribe();
  },
  */
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#content {
  margin-top: 140px;
  min-height: calc(100vh - 140px);
}

.heading {
  font-size: 40px;
  padding-top: 20px;   
}
</style>
