<template>
  <div v-if="product" class="product-panel d-flex flex-row py-5">
    <div class="f-2 text-center">
      <img class="product-image" :srcset="product.imgSrcSet" :sizes="product.imgSizes" />
    </div>
    <div class="f-1 mx-5">
      <h2>{{product.name}}</h2>
      <div class="mb-2 fs-6">
        Code: {{product.identifier}}
      </div>
      <div v-if="!product.out_of_stock">
        <h5>{{product.points}} {{PointsName}}</h5>

        <div class="d-flex flex-row" v-if="colours.length > 0">
          <div class="f-even">
            Colour
          </div>
          <div class="f-even">
            <select v-model="colour">
              <option v-for="c in colours" :key="c">{{c}}</option>
            </select>
          </div>
        </div>

        <div class="d-flex flex-row" v-if="models.length > 0">
          <div class="f-even">
            Model
          </div>
          <div class="f-even">
            <select v-model="model">
              <option v-for="m in models" :key="m">{{m}}</option>
            </select>
          </div>
        </div>

        <div class="d-flex flex-row" v-if="sizes.length > 0">
          <div class="f-even">
            Size
          </div>
          <div class="f-even">
            <select v-model="size">
              <option v-for="s in sizes" :key="s">{{s}}</option>
            </select>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="f-even">
            Quantity: 
          </div>
          <div class="f-even">
            <select v-model="quantity">
              <option v-for="index in [1,2,3,4,5,6,7,8,9,10]" :key="index">{{index}}</option>
            </select>
          </div>
        </div>
        <div class="my-3">
          <button class="btn btn-primary btn-sm" v-on:click="addToCart()">Add to cart</button>
        </div>
      </div>
      <div v-else>
        <h3>Out of Stock</h3>
      </div>
      <div class="py-4 fs-6" v-html="product.copy"></div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import {PointsName} from '@/services/config.service'
import cartService from '@/services/cart.service'
import {mapState} from "vuex"
//import _ from "lodash";

export default {
  props: ['groupedProduct'],
  data: function() {
    return {
      quantity: 1,
      colour: '',
      model: '',
      size: '',
      PointsName
    }
  },
  name: 'GroupProduct',
  components: {
  },
  computed: {
    products: function() {
      const products = [];
      this.merchandise.forEach(m => {
        if (m.group_id === this.groupedProduct.group_id) {
          products.push(m);
        }
      });
      return products;
    },
    product: function() {
      let result = {};

      this.products.forEach(m => {
        if (m.group_colour === this.colour &&
            m.group_model === this.model &&
            m.group_size === this.size) {
            
            result = m;
        }
      });

      return result;
    },
    colours: function() {
      const colours = [];
      this.products.forEach(p => {
        if (p.group_colour && p.group_colour.length > 0) {
          colours.push(p.group_colour);
        }
      });
      return colours;
    },
    models: function() {
      const models = [];
      this.products.forEach(p => {
        if (p.group_model && p.group_model.length > 0) {
          models.push(p.group_model);
        }
      });
      return models;
    },
    sizes: function() {
      const sizes = [];
      this.products.forEach(p => {
        if (p.group_size && p.group_size.length > 0) {
          sizes.push(p.group_size);
        }
      });
      return sizes;
    },
    ...mapState(['merchandise'])
  },
  methods: {
    addToCart() {
      const cartItem = {
        productIdentifier: this.product.identifier,
        type: this.product.type,
        name: this.product.name,
        description: this.product.copy,
        points: this.product.points,
        price: this.product.price,
        quantity: this.quantity
      };
      //console.log(cartItem);
      cartService.addCartItem(cartItem);
    }
  },
  mounted: function() {
    // initialize selected product
    if (this.colour + this.model + this.size === '') {
      this.colour = this.groupedProduct.group_colour;
      this.model = this.groupedProduct.group_model;
      this.size = this.groupedProduct.group_size;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-image {
  max-width: 100%;
  max-height: 60vh;
}

</style>