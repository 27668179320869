import axios from 'axios';
import headerUtils from "@/utils/header.utils";
import {AWSUrl, AWSProgrammeID} from '@/services/config.service';

export default {

  getSessionToken: (memberId: string) : Promise<string> => {
    const GET_TOKEN_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/authorization/sso/new/' + memberId;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<string>(GET_TOKEN_URL, requestData).then((response: any) => {
      if (response.data && response.data.token) {
        return response.data.token;
      }
      else {
        return '';
      }
    });
  },

  keepAlive: (token: string) : Promise<boolean> => {
    const KEEP_ALIVE_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/authorization/session/' + token + '/ping/1';
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<boolean>(KEEP_ALIVE_URL, requestData).then((response: any) => {
      console.log(response);
      if (response.data && response.data.status === 'OK') {
        return true;
      }
      else {
        return false;
      }
    });
  },
}
