import axios from 'axios';
import {Menu} from "@/models/menu.model";
import headerUtils from "@/utils/header.utils";
import {AWSUrl, AWSProgrammeID} from '@/services/config.service';


export default {

  getAllMenus: () : Promise<Menu[]> => {
    const GET_MENU_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/menu/all';
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get(GET_MENU_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        return response.data.menus;  
      }
      else {
        return [] as Menu[];
      }
    });
  },

  getMenusByType: (type: number) : Promise<Menu[]> => {
    const GET_MENU_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/menu/getbytype/' + type;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<Menu[]>(GET_MENU_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        return response.data.menus;
      }
      else {
        return [] as Menu[];
      }
    });
  },

}