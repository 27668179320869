/* eslint-disable */
import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
import {CognitoUserPool, CognitoUserAttribute, CognitoUser} from 'amazon-cognito-identity-js';
import IndexRouter from '@/router/index';
import { store } from '@/store';
import jwt_decode from 'jwt-decode';
import sessionService from '@/services/session.service';
import participantService from '@/services/participant.service';
import {AppUrl, CognitoClientID, CognitoAppDomain, CognitoRedirectURI, CognitoUserPoolID, CognitoRedirectSignout} from '@/services/config.service';

const userPoolData = {
  UserPoolId : CognitoUserPoolID,
  ClientId : CognitoClientID
}

const userPool = new CognitoUserPool(userPoolData);

const authData = {
  ClientId : CognitoClientID,
  AppWebDomain : CognitoAppDomain,
  TokenScopesArray : ['openid', 'email'],
  RedirectUriSignIn : CognitoRedirectURI,
  RedirectUriSignOut : CognitoRedirectSignout,
  UserPoolId : CognitoUserPoolID,
}

const auth = new CognitoAuth(authData);
auth.useCodeGrantFlow();
auth.userhandler = {
  onSuccess: function(result) {
    console.log("On Success result", result);

    const memberId = result.getAccessToken().getUsername();

    // retrieve Aciem session token
    sessionService.getSessionToken(memberId).then((sessionToken) => {
      store.commit("setSessionId", sessionToken);
      store.commit("setLoggedIn", true);
      
      console.log('session id: ', sessionToken);

      // retrieve participant
      participantService.getParticipantDetail().then((part) => {
        store.commit("setParticipant", part);
      });
      
      IndexRouter.push('/');  
    });

    //const store = useStore(key);
    
    // Call AWS to retrieve basic user details (email, username, email_verified etc)
    // We don't really need to do this, as we can get the username/email etc from the idToken/accessToken
    // Set getUsername() getEmail() below
    /*
    UserInfoService.getUserInfo().then(response => {
      const userInfoKey = getUserInfoStorageKey();
      storage.setItem(userInfoKey, JSON.stringify(response));

      IndexRouter.push('/');
    });
    */
  },
  onFailure: function(err) {
    console.log("On failure result", err);

    //const store = useStore(key);
    store.commit("setLoggedOut");
    store.commit("setSessionId", '');
    store.commit('setCognitoInfo', {});
    store.commit('setParticipant', {});
    
    IndexRouter.push('/error?message=' + encodeURIComponent(`Login failed due to ${err}`))
    //IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
  }
}

function getUserInfoStorageKey(){
  var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
  var tokenUserName = auth.getSignInUserSession().getAccessToken().getUsername();
  var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
  return userInfoKey;
}

function getUserName() : string | null | undefined {
  if (auth.isUserSignedIn()) {
    return auth.getSignInUserSession().getAccessToken().getUsername();
  } else {
    return '';
  }
}

function getEmail() : string | null | undefined {
  if (auth.isUserSignedIn()) {
    return (auth.getSignInUserSession().getIdToken().decodePayload() as any).email;
  } else {
    return '';
  }
}

function isSignedIn() : boolean {
  return auth.isUserSignedIn();
}

/**
 * Cognito stores a tokens etc in local storage by default
 * When logging out we clean up after Cognito by removing
 * the KVPs from local storage.
 */
var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();

export default {
  auth: auth,
  userPool: userPool,
  login(){
    auth.getSession();
  },
  logout(){
    if (auth.isUserSignedIn()) {
      var userInfoKey = this.getUserInfoStorageKey();
      auth.signOut();
      storage.removeItem(userInfoKey);
    }
  },
  getUserInfoStorageKey,
  getUserName,
  getEmail,
  isSignedIn
}