import axios from 'axios';
import { store } from '@/store';
import {AWSProduct, Product} from "@/models/product.model";
import headerUtils from "@/utils/header.utils";
import {Participant} from "@/models/participant.model";
import {HistoryItem} from "@/models/history.model";
import {Transaction} from "@/models/transaction.model";
import {AWSUrl, AWSProgrammeID} from '@/services/config.service';

export default {

  getParticipantDetail: () : Promise<Participant | null> => {
    const sessionToken = store.state.sessionId;
    const GET_PART_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/participant?token=' + sessionToken;
    const requestData = {
      headers: headerUtils.getBaseHeaders()
    }

    return axios.get<Participant | null>(GET_PART_URL, requestData).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        response.data.participant.balance = 1000;
        return response.data.participant as Participant;
      } else {
        return null;
      }
    });
  },

  getHistory: (dateFrom: Date, dateTo: Date) : Promise<HistoryItem[]> => {
    const sessionToken = store.state.sessionId;
    const GET_HISTORY_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/participant/history';

    const headers = {
      headers: headerUtils.getBaseHeaders()
    };

    const postData = JSON.stringify({
      token: sessionToken,
      dateFrom: dateFrom,
      dateTo: dateTo
    });

    return axios.post(GET_HISTORY_URL, postData, headers).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        return response.data.history as HistoryItem[];
      } else {
        return [] as HistoryItem[];
      }
    });
  },

  getTransactions: (dateFrom: Date, dateTo: Date) : Promise<Transaction[]> => {
    const sessionToken = store.state.sessionId;
    const GET_TRANSACTIONS_URL = 'https://' + AWSUrl + '/' + AWSProgrammeID + '/app/participant/history/transactions';

    const headers = {
      headers: headerUtils.getBaseHeaders()
    };

    const postData = JSON.stringify({
      token: sessionToken,
      dateFrom: dateFrom,
      dateTo: dateTo
    });

    return axios.post(GET_TRANSACTIONS_URL, postData, headers).then((response: any) => {
      if (response.data && response.data.status === 'OK') {
        return response.data.history as Transaction[];
      } else {
        return [] as Transaction[];
      }
    });
  },
}