<template>
  <div class="heading text-center">
    Merchandise
  </div>
  
  <div v-if="merchandise.length === 0" class="product-panel">
    <ProductLoadingPanel
      v-for="n in 20"
      v-bind:key="n"></ProductLoadingPanel>
  </div>
  <div v-else-if="products.length === 0">
    No products found
  </div>
  <div v-else class="product-panel">
    <ProductPanel
      v-for="product in products"
      v-bind:key="product.product_id"
      :product="product"></ProductPanel>
  </div>
  
</template>

<script>
// @ is an alias to /src
import productService from '@/services/product.service'
import ProductPanel from '@/components/ProductPanel.vue'
import ProductLoadingPanel from '@/components/ProductLoadingPanel.vue'
import {mapState} from "vuex";

export default {
  data: function() {
    return {
    }
  },
  props: ['category'],
  name: 'Merchandise',
  components: {
    ProductPanel,
    ProductLoadingPanel
  },
  computed: {
    products() {
      return productService.combineGroupedProducts(this.merchandise);
    },
    ...mapState(['merchandise'])
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .product-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>