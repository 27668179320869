
import {mapState} from "vuex";
import {PointsName} from '@/services/config.service';

export default {
  props: ['product'],
  data: function() {
    return {
      pointsName: PointsName
    }
  },
  computed: mapState(['loggedIn', 'sessionId']),
  methods: {
    openProductDetail: function() {
      const url = (this as any).product.productUrl;
      (this as any).$router.push(url);
    }
  }
}
