import { AWSBoolean } from '@/models/aws.boolean';
import { JobNo } from '@/services/config.service';
import _ from 'lodash';

const ImageSizes = [200, 350, 800];
const ImageResponsiveRules = '(max-width: 365px) 200px, (max-width: 576) 350px, 800px';

const ThumbSizes = [200, 250, 300];
const ThumbResponsiveRules = '(max-width: 365px) 200px, (max-width: 576) 250px, 300px';

export class Product {
  product_id: string = '';
  identifier: string = '';
  jobNo: string = '';
  type: number = 0;
  name: string = '';
  code: string = '';
  details: string = '';
  copy: string = '';
  points: number = 0;
  original_points: number = 0;
  image_url: string = '';
  out_of_stock: boolean = false;
  special: boolean = false;
  deal: boolean = false;
  ppp: boolean = false;
  ppp_bonus: boolean = false;
  crisp: boolean = false;
  bonus_rate: number = 0;
  delivery_cost: number = 0;
  cost: number = 0;
  invoice_cost: number = 0;
  flags_csv: string = '';
  display_type: string = '';
  deal_count: number = 0;
  max_deal_qty: number = 0;
  price: number = 0;
  group_id: string = '';
  group_name: string = '';
  group_size: string = '';
  group_colour: string = '';
  group_model: string = '';
  supplier: string = '';
  date_from: Date = new Date('1900-01-01 00:00');
  date_to: Date = new Date('1900-01-01 00:00');
  update_date: Date = new Date('1900-01-01 00:00');
  modified_by: string = ''
  is_error: boolean = false;
  error: string = '';
  has_gst: boolean = false;
  approved_by: string = '';
  approved_date: Date = new Date('1900-01-01 00:00');
  page_name: string = '';
  paths: string = '';
  pointsRate: number = 0;
  productDetailImage: string = '';
  thumbnailImage: string = '';
  cartImage: string = '';
  checkoutImage: string = '';
  specialsImage: string = '';
  aciemimg: string = '';
  breadCrumb: string = '';

  // custom properties
  isGrouped: boolean;

  // maps a product received from AWS
  constructor(product: AWSProduct) {
    for(const [key, value] of Object.entries(product)) {
      if (value) {
        // @ts-ignore
        if (typeof this.getProperty(this, key) === 'boolean') {
          (this as any)[key] = (value as AWSBoolean).data[0] === 1;
        } else {
          (this as any)[key] = value;
        }
      }
    }

    // set custom properties
    this.isGrouped = (this.group_name !== undefined && this.group_name.length > 0);

    this.pointsRate 	= parseFloat((this as any).points) / parseFloat((this as any).price);
  }

  private getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName];
  }

  /**
   * Returns a responsive image set to reduce image loading times on mobile devices
   */
  get imgSrcSet(): string {
    return this.getSrcSet(ImageSizes);
  }
  get thumbSrcSet(): string {
    return this.getSrcSet(ThumbSizes);
  }

  get imgSizes(): string {
    return ImageResponsiveRules;
  }
  get thumbSizes(): string {
    return ThumbResponsiveRules;
  }

  get productUrl(): string {
    return '/product/' + this.code + '/' + encodeURIComponent(_.replace(this.name, ' ', '_'));
  }

  private getSrcSet(imageSizes: number[]): string {
    let output = '';
    imageSizes.forEach(imageSize => {
      // Perform substitution
      // https://imageserver.aciem.co.nz/image.aspx?jobno=367&x=598&my=449&img=productHQ/TC5338.png
      let imgUrl = '';
      if (this.type === 2 || this.type === 4) {
        imgUrl = `https://imageserver.aciem.co.nz/image.aspx?jobno=${JobNo}&x=${imageSize}&img=supplier/${this.code}4.png`;

      } else if (this.image_url.indexOf('&x=') > -1) {
        const startIdx = this.image_url.indexOf('&x=');
        const endIdx = this.image_url.indexOf('&img=');
        const startStr = this.image_url.substr(0, startIdx);
        const endStr = this.image_url.substr(endIdx);
        imgUrl = `${startStr}&x=${imageSize}${endStr}`;
      }

      if (output.length > 0) {
        output += ', ';
      }
      output += `${imgUrl} ${imageSize}w`;
    });

    return output;
  }
}

export interface AWSProduct {
  product_id: string;
  identifier: string;
  jobNo: string;
  type: number;
  name: string;
  code: string;
  details: string;
  copy: string;
  points: number;
  original_points: number;
  image_url: string;
  out_of_stock: AWSBoolean;
  special: AWSBoolean;
  deal: AWSBoolean;
  ppp: AWSBoolean;
  ppp_bonus: AWSBoolean;
  crisp: AWSBoolean;
  bonus_rate: number;
  delivery_cost: number;
  cost: number;
  invoice_cost: number;
  flags_csv: string;
  display_type: string;
  deal_count: number;
  max_deal_qty: number;
  price: number;
  group_id: string;
  group_name: string;
  group_size: string;
  group_colour: string;
  group_model: string;
  supplier: string;
  date_from: Date;
  date_to: Date;
  update_date: Date;
  modified_by: string;
  is_error: AWSBoolean;
  error: string;
  has_gst: AWSBoolean;
  approved_by: string;
  approved_date: Date;
  page_name: string;
  paths: string;
  pointsRate: number;
  productDetailImage: string;
  thumbnailImage: string;
  cartImage: string;
  checkoutImage: string;
  specialsImage: string;
  aciemimg: string;
  breadCrumb: string;
}
