<template>
  <div v-if="product" class="product-panel d-flex flex-row py-5">
    <div class="f-2 text-center">
      <img class="product-image" :srcset="voucherProduct.imgSrcSet" :sizes="voucherProduct.imgSizes" />
    </div>
    <div class="f-1 mx-5">
      <h2>{{voucherProduct.name}}</h2>
      <div class="mb-3 fs-6">
        Code: {{voucherProduct.identifier}}
      </div>
      <div v-if="!voucherProduct.out_of_stock">
        <div v-if="voucherProduct.isGrouped && inverseTypeExists()" class="d-flex flex-row mb-3">
          <div class="f-even">
            <input type="radio" id="gift_card" name="gift_card" v-model="cardType" value="2">
            <label for="gift_card" class="ms-2 fs-5">Gift Card</label>
          </div>
          <div class="f-even">
            <input type="radio" id="e_voucher" name="gift_card" v-model="cardType" value="4">
            <label for="e_voucher" class="ms-2 fs-5">eVoucher</label>
          </div>
        </div>
        <div class="d-flex flex-row mb-3">
          <div class="f-even">
            Quantity: 
          </div>
          <div class="f-even">
            <select v-model="quantity">
              <option v-for="q in quantities" :key="q.value" :value="q.value">{{q.label}}</option>
            </select>
          </div>
        </div>
        <h5>{{voucherProduct.points}} {{PointsName}}</h5>
        <div class="my-3">
          <button class="btn btn-primary btn-sm" v-on:click="addToCart()">Add to cart</button>
        </div>
      </div>
      <div v-else>
        <h3>Out of Stock</h3>
      </div>
      <div class="py-4 fs-6" v-html="product.copy"></div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import {PointsName} from '@/services/config.service'
import cartService from '@/services/cart.service'
import {MinVoucherRedemption} from '@/services/config.service'
import {mapState} from 'vuex'
import _ from "lodash";

export default {
  props: ['product'],
  data: function() {
    return {
      quantity: 1,
      PointsName,
      cardType: this.product.type,
      voucherProduct: this.product,
      quantities: []
    }
  },
  name: 'GiftCard',
  components: {
  },
  computed:{
    ...mapState(['giftCards', 'eVouchers'])
  },
  watch: {
    cardType: function(val) {
      // retrieve associated product
      if (val == 2) {
        this.voucherProduct = _.find(this.giftCards, (c) => c.group_id === this.product.group_id && c.type === 2);
      } else if (val == 4) {
        this.voucherProduct = _.find(this.eVouchers, (c) => c.group_id === this.product.group_id && c.type === 4);
      }
      
      if (this.voucherProduct) {
        this.initQuantities();
      }
    }
  },
  mounted() {
    this.initQuantities();

    // initialize selected option
    if (this.quantities.length > 0) {
      this.quantity = Number(this.quantities[0].value);
    }
  },
  methods: {
    addToCart() {
      let totalPoints = 0;
      let totalPrice = 0;
      let cartItemName = '';
      let qty = parseFloat(this.quantity, 10);

      if (this.voucherProduct.display_type === 5) {
        //Movie Vouchers etc (quantity based)
        totalPoints += qty * parseFloat(this.voucherProduct.points, 10);
        totalPrice  += (qty * parseFloat(this.voucherProduct.points, 10)) / parseFloat(this.voucherProduct.pointsRate,10);
        cartItemName = qty + " x " + this.voucherProduct.name;
        //quantity = 1; //Reset quantity to 1 (it arrives as voucher dollar value)
      } else {
        //Voucher / e-Voucher
        console.log(this.voucherProduct);
        totalPoints += qty * parseFloat(this.voucherProduct.pointsRate, 10);
        totalPrice  += qty;
        cartItemName = "$" + totalPrice + " " + this.voucherProduct.name;
        qty = 1; //Reset quantity to 1 (it arrives as voucher dollar value)
      }

      const cartItem = {
        productIdentifier: this.voucherProduct.identifier,
        type: this.voucherProduct.type,
        name: cartItemName,
        description: this.voucherProduct.copy,
        points: this.voucherProduct.points,
        price: this.voucherProduct.price,
        quantity: qty,
        totalPoints,
        totalPrice
      };
      //console.log(cartItem);
      cartService.addCartItem(cartItem);
    },
    initQuantities() {
      // populate quantities
      if( this.voucherProduct.display_type === 5 ) {
        const opts = [];
        for(let quantity = 1; quantity <= 10; quantity++) {
          opts.push({label: String(quantity), value: String(quantity)});
        }
        this.quantities = opts;
      } else {
        //Set denominations
        const denominations = this.voucherProduct.details.split(':');

        let _quantities = [];
        denominations.forEach((d) => {
          //Multiply denomination by 1 to 100
          for( let i = 1; i <= 100; i++ ){
            let quantity = parseInt(d,10) * i;

            //Quantity displayed must be equal or larger than the 
            //minimum voucher redemption amount.
            if( quantity >= MinVoucherRedemption ){
              //Display up to $500 worth of vouchers
              if( quantity <= 500 ){
                _quantities.push(quantity);
              }
            }

            //Combine every other denomination with this one
            denominations.forEach((d2) => {
              let quantityOther = quantity + parseInt(d2, 10);

              //Quantity displayed must be equal or larger than the
              //minimum voucher redemption amount.
              if( quantityOther >= MinVoucherRedemption ){
                //Display up to $500 worth of vouchers
                if( quantityOther <= 500 ){
                  _quantities.push(quantityOther);
                }
              }
            });
          }
        });

        //Sort quantities, and remove dupes
        _quantities = this.sortUnique(_quantities);

        const opts = [];
        _quantities.forEach((d) =>{
          opts.push({label: '$' + d, value: '' + d});
        });
        this.quantities = opts;
      }
    },
    sortUnique(arr) {
      if (arr.length === 0) return arr;
      arr = arr.sort(function (a, b) { return a*1 - b*1; });
      var ret = [arr[0]];
      for (var i = 1; i < arr.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
        if (arr[i-1] !== arr[i]) {
          ret.push(arr[i]);
        }
      }
      return ret;
    },
    inverseTypeExists() {
      let exists = false;

      if (this.voucherProduct.type == 2) {     
        exists = _.find(this.eVouchers, (c) => c.group_id === this.product.group_id && c.type === 4) !== undefined;
      } else if (this.voucherProduct.type == 4) {
        exists = _.find(this.giftCards, (c) => c.group_id === this.product.group_id && c.type === 2) !== undefined;
      }

      return exists;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-image {
  max-width: 100%;
  max-height: 60vh;
}

</style>