<template>
  <h4 class="text-start p-3">Your cart</h4>
  <div v-if="cart.length > 0" class="cart-panel">
    <div v-for="cartItem in cart" :key="cartItem.cartId" class="cartitem-panel">
      <div class="cartitem-image text-center" v-on:click="gotoProduct(cartItem.productIdentifier)">
        <img :srcset="product(cartItem.productIdentifier).thumbSrcSet" 
            :sizes="product(cartItem.productIdentifier).thumbSizes" />
      </div>
      <div class="cartitem-name px-4">
        <p class="product-name fs-6" v-on:click="gotoProduct(cartItem.productIdentifier)">
          {{cartItem.name}} x {{cartItem.quantity}}
        </p>
      </div>
      <div class="cartitem-total fs-6 text-end">
        {{formatNumber(cartItem.totalPoints)}}
      </div>
    </div>
    <div class="d-flex flex-row mb-4">
      <div class="flex-grow-1"></div>
      <div class="flex-grow-1 fw-bold fs-5 text-end px-2">
        Total {{pointsName}}
      </div>
      <div class="flex-grow-1 fw-bold fs-5 text-end">
        {{formatNumber(total)}}
      </div>
    </div>
    <div class="text-end">
      <p><a href="javascript:void(0);" v-on:click="gotoCart()">Edit cart</a></p>
      <p><a href="javascript:void(0);" v-on:click="gotoHome()">Continue shopping</a></p>
    </div>
  </div>
  <div v-if="cart.length === 0">
    <p>Your cart is empty</p>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapState} from "vuex"
import {PointsName} from '@/services/config.service'
import numberUtils from '@/utils/number.utils'
import _ from 'lodash'

export default {
  data: function() {
    return {
      pointsName: PointsName
    }
  },
  name: 'CartPanel',
  components: {
  },
  computed: {
    total() {
      return _.sumBy(this.cart, ci => ci.totalPoints);
    },
    ...mapState(['cart','merchandise','giftCards','eVouchers'])
  },
  methods: {
    ...numberUtils,
    product(productId) {
      if (this.merchandise && this.giftCards && this.eVouchers) {
        let result = _.find(this.merchandise, (m) => m.identifier === productId);

        if (!result) {
          result = _.find(this.giftCards, (m) => m.identifier === productId);
        }

        if (!result) {
          result = _.find(this.eVouchers, (m) => m.identifier === productId);
        }

        return result;
      }
      else {
        return {};
      }
    },
    gotoProduct(productId) {
      const url = this.product(productId).productUrl;
      this.$router.push(url);
    },
    gotoCart() {
      this.$router.push({name: 'cart'});
    },
    gotoHome() {
      this.$router.push({name: 'home'});
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-panel {
  padding-top: 10px;
}

.cartitem-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;

  img {
    width: auto;
    height: 75px;
    max-width: 100px;
    cursor: pointer;
  }

  div {
    flex: 1;
  }
  .cartitem-name {
    flex: 2;
  }
  .product-name {
    cursor: pointer;
  }
}

</style>